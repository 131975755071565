.form-drawer .ant-drawer-header-title {
	flex-direction: row-reverse;
}

.form-drawer .ant-drawer-header {
	padding-top: 0.625rem;
	padding-bottom: 0.625rem;
}

.form-drawer .ant-drawer-close {
	margin: 0;
}

.form-drawer .ant-form-item-label label {
	text-transform: capitalize;
}

.form-drawer .ant-form-item-label label.ant-form-item-required::after {
	content: "*";
	display: inline-block;
	margin-left: 0.25rem;
	color: #ff4d4f;
}

.form-drawer .ant-form-item-label label.ant-form-item-required::before {
	content: none !important;
}

.form-drawer .ant-row.ant-form-item {
	@apply mb-[1.875rem];
}
