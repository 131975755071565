.combine-data-cell.table-cell {
	@apply !flex;
}

.combine-data-cell * {
	@apply first-letter:uppercase;
}

.combine-data-cell ul {
	@apply m-0 p-0 list-none;
}

.combine-data__today {
	@apply text-base m-0;
}
