 .ant-form-item-label > label::after {
    display: none;
} 
.form-drawer .ant-drawer-header-title {
	flex-direction: row-reverse;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    position: absolute;
    left: 55px;
    top: 2px;
}

.ant-form-horizontal .ant-form-item-label {
    padding-right: 25px;
} 
.ant-drawer-header-title > button {
    position: absolute;
    top: 20px;
    right: 8px;
}