@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

/* @tailwind base; */
@tailwind components;
@import "~antd/dist/antd.css";
@tailwind utilities;

html {
	font-size: 100%;
}

body {
	margin: 0;
	font-family: "Roboto", sans-serif;
	font-size: 0.875rem;
	font-weight: 400;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
	width: 0.5rem;
	height: 0.5rem;
}

::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.15);
	border-top-left-radius: 1rem;
	border-top-right-radius: 1rem;
	border-bottom-right-radius: 1rem;
	border-bottom-left-radius: 1rem;
}

::-webkit-scrollbar-thumb {
	background-color: #d9d9d9;
	border-top-left-radius: 1rem;
	border-top-right-radius: 1rem;
	border-bottom-right-radius: 1rem;
	border-bottom-left-radius: 1rem;
}

.ant-form-horizontal .ant-form-item-control {
	flex: 1;
}

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}
