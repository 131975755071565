.ant-table-cell {
    width: 200px;
    padding: 0px;
}
.ant-table-cell.ant-table-selection-column {
    width: 100px;
}
.ant-table-thead tr {
    height: 57px;
}
.selectStoreTableMenus .ant-select.ant-select-single,
.priceTableMenus .ant-form-item-control-input {
    width: 170px;
}
.ant-table-tbody .ant-table-row .ant-table-cell {
    background: none;
}

.ant-row.ant-form-item .ant-form-item-explain {
    min-height: 0px;
}
.table_menus .ant-table-cell .ant-form-item {
    margin-bottom: 0%;
}
.ant-form-item-explain.ant-form-item-explain-connected .ant-form-item-explain-error {
    margin-top: 0%;
}
.form_drawer-tableMenus.ant-form-item-has-error .ant-select-selection-placeholder,
.selectStoreTableMenus.ant-form-item-has-error .ant-select-selection-placeholder {
    color: red;
}
.form_drawer-tableMenus.ant-form-item-has-error .ant-form-item-explain {
    display: none;
}
.my-3::after {
    content: "*";
    display: inline-block;
    margin-left: 0.25rem;
    color: #ff4d4f;
}