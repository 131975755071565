.today-table {
	@apply mt-5;
}

.rows-in-cell {
	@apply p-0 flex-1 m-0 flex flex-col;
}

.today-table .table-row {
	@apply flex flex-row flex-1;
}
.today-table .table-cell {
	@apply p-4 block border-0 border-b border-b-[#f4f4f4] border-solid first-letter:uppercase;
	word-break: break-word;
}
.col-gray {
	@apply bg-gray-50;
}
.today-table .table-title {
	@apply bg-gray-50 flex flex-row items-baseline;
}

.today-table .table-head {
	@apply font-medium relative after:h-[50%] after:bg-gray-200 after:w-[1px] after:absolute after:right-0 after:-translate-y-1/2 after:top-1/2;
}
.today-table .table-head::after {
	content: "";
	transform: translateY(-50%);
}

.today-table .user-name-cell {
	@apply flex justify-between items-baseline;
}

.today-table .store-col {
	@apply w-[15.5%];
}
.today-table .dish-col {
	@apply w-[23.08%];
}
.today-table .member-col {
	@apply w-[72.2%];
}
.today-table .office-col {
	@apply w-[27.6%];
}
.today-table .combine-col {
	@apply w-[28.66%];
}
